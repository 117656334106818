import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import ImagesSlider, {
  SliderImage,
} from '../../../../general/components/imagesSlider/ImagesSlider';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { setHostingToEdit } from '../../../../general/redux/reducers/HostingLocations-Reducer';
import { RootState } from '../../../../general/redux/store';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import { HostingLocationStates, IHostingLocation } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface StoreEditorProps {
  isOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const HostingLocationEditor: React.FC<StoreEditorProps> = ({ isOpen, refresh }) => {
  const dispatch = useDispatch();
  const hostingToEdit = useSelector((state: RootState) => state.hosting.hostingToEdit);
  const [imagesIds, setImagesIds] = useState<
    {
      id: string;
      url: string;
    }[]
  >([]);
  const [allImages, setAllImages] = useState<SliderImage[]>([]);

  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [deleteArticleAsk, setDeleteArticleAsk] = useState(false);

  const [wasSaved, setWasSaved] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [formData, setFormData] = useState<
    | IHostingLocation
    | {
        locationName: string;
        state: string;
        characteristics: {
          key: string;
          value: string;
        }[];
        mapPoinerLocationX: number;
        mapPoinerLocationY: number;
        isActive: boolean;
      }
  >({
    locationName: hostingToEdit ? hostingToEdit.locationName : '',
    state: hostingToEdit ? hostingToEdit.state : '',
    characteristics: hostingToEdit ? hostingToEdit.characteristics : [],
    mapPoinerLocationX: hostingToEdit ? hostingToEdit.mapPoinerLocationX : 0,
    mapPoinerLocationY: hostingToEdit ? hostingToEdit.mapPoinerLocationY : 0,
    isActive: hostingToEdit ? hostingToEdit.isActive : false,
  });

  const { data: states } = useCustomQuery<HostingLocationStates>(
    queryKeys.HOSTING_LOCATION_STATES,
    () => createShippingService(adminInstance).getHostingLocationStates(),
  );

  const { mutate: createNewHostingLocation, isLoading: creatingHosting } = useCustomMutation(
    createShippingService(adminInstance).createHostingLocation,
    undefined,
    {
      onSuccess: () => {
        refresh();
        setWasSaved(true);
      },
    },
  );

  const { mutate: updateHostingLocation, isLoading: updatingHosting } = useCustomMutation(
    createShippingService(adminInstance).updateHostingLocation,
    undefined,
    {
      onSuccess: () => {
        refresh();
      },
    },
  );

  const { mutate: createImg, isLoading: isCreatingImage } = useCustomMutation(
    createShippingService(adminInstance).createImageForHosting,
    undefined,
    {
      onSuccess: (data: any) => {
        setImagesIds((prevImagesIds) => [...prevImagesIds, data]);
      },
    },
  );

  const { mutate: addImg, isLoading: isAddingImage } = useCustomMutation(
    createShippingService(adminInstance).addImageToHosting,
    undefined,
    {
      onSuccess: async (data: any) => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'New picture(s) succesfully updated' },
          }),
        );

        if (hostingToEdit) {
          dispatch(
            setHostingToEdit({
              ...hostingToEdit,
              images: [...hostingToEdit.images, data],
            }),
          );
        }

        refresh();
      },
    },
  );

  const { mutate: deleteImg } = useCustomMutation(
    createShippingService(adminInstance).removeImgFromHosting,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Picture(s) succesfully remved' },
          }),
        );

        if (hostingToEdit != null) {
          const updatedImages = hostingToEdit?.images.filter((img) => img.id !== idToDelete);

          if (updatedImages != null) {
            const updatedHostingToEdit: IHostingLocation = {
              ...hostingToEdit,
              images: updatedImages,
            };

            dispatch(setHostingToEdit(updatedHostingToEdit));
          }
        }

        refresh();
      },
    },
  );

  useEffect(() => {
    if (hostingToEdit) {
      setFormData({
        locationName: hostingToEdit.locationName,
        state: hostingToEdit.state,
        characteristics: hostingToEdit.characteristics,
        mapPoinerLocationX: hostingToEdit.mapPoinerLocationX,
        mapPoinerLocationY: hostingToEdit.mapPoinerLocationY,
        isActive: hostingToEdit.isActive,
      });
    }
  }, [hostingToEdit]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, type, value } = e.target;
    const checked = (e.target as HTMLInputElement).checked;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddPictureClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Открываем диалог выбора файла
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      //setImagesFiles((prevFiles) => [...prevFiles, ...fileList]);

      if (hostingToEdit) {
        fileList.forEach((file) => {
          addImg({ hostingLocationId: hostingToEdit.id, file: file });
        });
      } else {
        fileList.forEach((file) => {
          createImg(file);
        });
      }
    }

    event.target.value = '';
  };

  const handleCharacteristicChange = (index: number, key: string, value: string) => {
    setFormData((prevFormData) => {
      const newCharacteristics = [...prevFormData.characteristics];
      newCharacteristics[index] = { ...newCharacteristics[index], [key]: value };
      return { ...prevFormData, characteristics: newCharacteristics };
    });
  };

  const handleDeleteCharacteristic = (index: number) => {
    setFormData((prevFormData) => {
      const newCharacteristics = prevFormData.characteristics.filter((_, i) => i !== index);
      return { ...prevFormData, characteristics: newCharacteristics };
    });
  };

  const handleAddCharacteristic = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      characteristics: [...prevFormData.characteristics, { key: '', value: '' }],
    }));
  };

  const handleSave = () => {
    const { locationName, state, characteristics } = formData;

    //#region validation
    if (!locationName || !state || characteristics.length === 0) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Please fill in all required fields (locationName, state, characteristics)' },
        }),
      );

      return;
    }

    const hasEmptyCharacteristic = characteristics.some(({ key, value }) => !key || !value);

    if (hasEmptyCharacteristic) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Please ensure all characteristics have both key and value filled in.' },
        }),
      );
      return;
    }
    //#endregion

    if (!hostingToEdit) {
      const body = {
        ...formData,
        imagesIds: imagesIds.map((image) => image.id),
        mapPoinerLocationX: formData.mapPoinerLocationX,
        mapPoinerLocationY: formData.mapPoinerLocationY,
        isActive: formData.isActive,
      };

      createNewHostingLocation(body);
    } else {
      updateHostingLocation({ id: hostingToEdit.id, ...formData });
    }
    isOpen(false);
  };

  const getAllImages = useCallback(() => {
    if (hostingToEdit) {
      return hostingToEdit.images;
    }
    if (imagesIds.length > 0) {
      return imagesIds;
    }
    return imagesIds;
  }, [hostingToEdit, imagesIds]);

  useEffect(() => {
    setAllImages(getAllImages());
  }, [hostingToEdit, imagesIds, getAllImages]);

  return (
    <AdminModalWindow
      mWidth={'80%'}
      isOpen={(isClose) => {
        isOpen(isClose);
        if (!wasSaved && imagesIds.length > 0) {
          imagesIds.forEach((image) => {
            deleteImg(image.id);
          });
        }
      }}
      title={'Hosting location'}
      saveCallback={refresh}
    >
      {(creatingHosting || updatingHosting) && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__hosting_location">
        <div className="modal-window-action__modal__hosting_location">
          <div className="images">
            <div>
              <div className="hosting-location-images">
                {(hostingToEdit && hostingToEdit.images && hostingToEdit.images.length > 0) ||
                allImages.length > 0 ? (
                  <ImagesSlider
                    images={allImages}
                    removeCallback={(imgId: string) => {
                      setIdToDelete(imgId);
                      setDeleteArticleAsk(true);
                    }}
                  />
                ) : (
                  <div className="no-images-container">
                    <span>No images</span>
                  </div>
                )}
              </div>
              <button className="section-btn-gradient" onClick={handleAddPictureClick}>
                Add new picture
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple={false}
              />
              <div className="marker-postion">
                <span className="part-header">Location pointer position</span>
                <input
                  className="input_symbols_panel"
                  type="number"
                  placeholder="marker position x"
                  name="mapPoinerLocationX"
                  value={formData.mapPoinerLocationX}
                  onChange={handleInputChange}
                />
                <input
                  className="input_symbols_panel"
                  type="number"
                  placeholder="marker position y"
                  name="mapPoinerLocationY"
                  value={formData.mapPoinerLocationY}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="check-box__container">
              <input
                type="checkbox"
                id={`isActiveHostingLocation${hostingToEdit?.id}`}
                name="isActive"
                checked={formData.isActive}
                onChange={handleInputChange}
              />
              <label htmlFor={`isActiveHostingLocation${hostingToEdit?.id}`}>
                {formData.isActive ? 'Active' : 'Not Active'}
              </label>
            </div>
          </div>
          <div className="info">
            <input
              className="input_symbols_panel"
              type="text"
              placeholder="Locatin name"
              name="locationName"
              value={formData.locationName}
              onChange={handleInputChange}
            />

            <select name="state" value={formData.state} onChange={handleInputChange}>
              <option value="" hidden>
                Select State
              </option>
              {states &&
                Object.entries(states).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
            </select>

            <div className="tool-bar">
              <span className="part-header">Characteristic</span>
              <div className="action-buttons">
                <button className="section-btn-gradient" onClick={handleAddCharacteristic}>
                  Add new characteristic
                </button>
              </div>
            </div>
            <div className="key-value-pairs">
              {formData.characteristics.length > 0 ? (
                formData.characteristics.map((characteristic, index) => (
                  <div className="key-value" key={index}>
                    <input
                      className="input_symbols_panel"
                      type="text"
                      placeholder="key"
                      value={characteristic.key}
                      onChange={(e) => handleCharacteristicChange(index, 'key', e.target.value)}
                    />
                    <input
                      className="input_symbols_panel"
                      type="text"
                      placeholder="value"
                      value={characteristic.value}
                      onChange={(e) => handleCharacteristicChange(index, 'value', e.target.value)}
                    />
                    <button onClick={() => handleDeleteCharacteristic(index)}>
                      <DeleteActionIcon />
                    </button>
                  </div>
                ))
              ) : (
                <span className="no-characteristics">No characteristics</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        <button
          className="section-btn-gradient"
          disabled={isCreatingImage || isAddingImage}
          style={{ background: isCreatingImage || isAddingImage ? 'gray' : '' }}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      {deleteArticleAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want to delete this picture?'}
          handler={setDeleteArticleAsk}
          clickEvent={() => {
            setImagesIds((prevImagesIds) => prevImagesIds.filter((img) => img.id !== idToDelete));
            deleteImg(idToDelete as string);
          }}
        />
      )}
    </AdminModalWindow>
  );
};

export default HostingLocationEditor;
