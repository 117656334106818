import React, { ChangeEvent, DragEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setInfo } from '../../../../../general/redux/reducers/Error-Reducer';
import Confirm from '../../../../components/confirm/Confirm';

export interface EditImage {
  url: string;
  id: string;
}

interface UploadImagesProps {
  filesArray: File[]; // Новые файлы
  dragedImgs: { goal: string | null; targetIndex: number }[];
  editImages: EditImage[]; // Уже существующие изображения
  removeArray: string[];
}

const MAX_IMAGES = 10;

const UploadImages: React.FC<UploadImagesProps> = ({
  filesArray,
  dragedImgs,
  editImages,
  removeArray,
}) => {
  const dispatch = useDispatch();
  const [imageSlots, setImageSlots] = useState<Array<File | EditImage | null>>(
    Array(MAX_IMAGES).fill(null),
  );
  const [deleteImgAsk, setDeleteImgAsk] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  // Метод для обновления слотов
  const updateSlots = useCallback(() => {
    const combined = [...editImages, ...filesArray].slice(0, MAX_IMAGES);
    const slots = Array(MAX_IMAGES).fill(null) as Array<File | EditImage | null>;
    for (let i = 0; i < combined.length; i++) {
      slots[i] = combined[i];
    }
    setImageSlots(slots);
  }, [editImages, filesArray]);

  useEffect(() => {
    updateSlots();
  }, [updateSlots]);

  const setUploadingSizeError = (errors: { f: string; s: string }[]) => {
    dispatch(
      setInfo({
        n: 'Uploading images',
        r: {
          d: '',
          s: `Some images weigh more than 200 kB.\n\n${errors
            .map((error) => `${error.f} - ${error.s} kB`)
            .join('\n')}`,
        },
      }),
    );
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const files = Array.from(event.target.files);
    const errors: { f: string; s: string }[] = [];

    files.forEach((file) => {
      const sizeInKB = file.size / 1024;
      if (sizeInKB > 200) {
        errors.push({ f: file.name, s: sizeInKB.toFixed(2) });
      }
    });

    if (errors.length > 0) {
      setUploadingSizeError(errors);
    }

    files.forEach((file) => {
      const sizeInKB = file.size / 1024;
      if (sizeInKB <= 200 && editImages.length + filesArray.length < MAX_IMAGES) {
        filesArray.push(file);
      }
    });

    event.target.value = '';
    updateSlots();
  };

  const handleDropImages = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      const errors: { f: string; s: string }[] = [];

      droppedFiles.forEach((file) => {
        const sizeInKB = file.size / 1024;
        if (sizeInKB > 200) {
          errors.push({ f: file.name, s: sizeInKB.toFixed(2) });
        }
      });

      if (errors.length > 0) {
        setUploadingSizeError(errors);
      }

      droppedFiles.forEach((file) => {
        const sizeInKB = file.size / 1024;
        if (sizeInKB <= 200 && editImages.length + filesArray.length < MAX_IMAGES) {
          filesArray.push(file);
        }
      });

      updateSlots();
    }
  };

  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index);
    setDeleteImgAsk(true);
  };

  const deleteImg = () => {
    if (deleteIndex === null) return;
    const item = imageSlots[deleteIndex];
    if (!item) return;

    if ('id' in item) {
      // Существующее изображение
      removeArray.push(item.id);
      const idx = editImages.findIndex((img) => img.id === item.id);
      if (idx !== -1) {
        editImages.splice(idx, 1);
      }
    } else {
      // Новый файл
      const fileIdx = filesArray.indexOf(item);
      if (fileIdx !== -1) {
        filesArray.splice(fileIdx, 1);
      }
    }

    setDeleteIndex(null);
    updateSlots();
  };

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>, index: number) => {
    e.dataTransfer.setData('draggedIndex', index.toString());
  };

  const handleDrop = (e: React.DragEvent<HTMLLIElement>, targetIndex: number) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData('draggedIndex'), 10);
    if (draggedIndex === targetIndex) return;

    const newSlots = [...imageSlots];
    const [draggedItem] = newSlots.splice(draggedIndex, 1);
    newSlots.splice(targetIndex, 0, draggedItem);

    // Синхронизируем изменения обратно в editImages и filesArray
    const newDisplayed = newSlots.filter((item) => item !== null) as (File | EditImage)[];
    const newEditImages = newDisplayed.filter((img): img is EditImage => 'id' in img);
    const newFiles = newDisplayed.filter((img): img is File => img instanceof File);

    editImages.splice(0, editImages.length, ...newEditImages);
    filesArray.splice(0, filesArray.length, ...newFiles);

    if (draggedItem && 'id' in draggedItem) {
      // Если перетащили существующее изображение, фиксируем
      dragedImgs.push({
        goal: draggedItem.url,
        targetIndex,
      });
    }

    setImageSlots(newSlots);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
  };

  const disableInput = { display: 'none' as const };

  return (
    <>
      <ul className="product-content-section__side-bar__image-loaders">
        {imageSlots.map((slot, index) => {
          const isEdit = slot && 'id' in slot;
          const imgUrl = slot ? (isEdit ? slot.url : URL.createObjectURL(slot as File)) : '';

          return (
            <li
              key={index}
              onClick={() => slot && handleDeleteClick(index)}
              className="image-loaders"
              draggable={slot !== null}
              onDragStart={(e) => slot && handleDragStart(e, index)}
              onDrop={(e) => slot && handleDrop(e, index)}
              onDragOver={handleDragOver}
            >
              {slot ? (
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ) : (
                <label
                  htmlFor={`upload-photo-${index}`}
                  onDrop={handleDropImages}
                  onDragOver={(e) => e.preventDefault()}
                  className="img-loader"
                >
                  +
                </label>
              )}
              <input
                id={`upload-photo-${index}`}
                type="file"
                multiple
                onChange={handleImageChange}
                style={slot ? disableInput : undefined}
              />
            </li>
          );
        })}
      </ul>

      {deleteImgAsk && (
        <Confirm
          title="Delete confirm"
          text="Are you sure you want to delete this image?"
          handler={setDeleteImgAsk}
          clickEvent={deleteImg}
        />
      )}
    </>
  );
};

export default UploadImages;
