import './admin/assets/styles/AdminGlobal.scss';
import './client/assets/styles/ClientGlobal.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import InjectGtag from './client/components/SEO/injectGtag';
import store, { persister } from './general/redux/store';
import { googleFeatures } from './general/StaticData';
import { cleanCacheForage } from './general/utils/localforage';

export const isPrerendering = navigator.userAgent === 'ReactSnap';
export const queryClient = new QueryClient();

// Создаём маршрутизатор
const router = createBrowserRouter([
  {
    path: '*', // Главный путь
    element: <App />, // App как оболочка маршрутов
  },
]);

const initializeApp = async () => {
  await cleanCacheForage();
  const rootElement = document.getElementById('root');

  queryClient.clear();
  queryClient.refetchQueries();

  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persister}>
            <QueryClientProvider client={queryClient}>
              {!isPrerendering && (
                <GoogleOAuthProvider clientId={googleFeatures.authClientId}>
                  <BrowserRouter>
                    <InjectGtag />
                    <App />
                  </BrowserRouter>
                </GoogleOAuthProvider>
              )}
              {isPrerendering && (
                // Фоллбэк для react-snap
                <RouterProvider router={router} />
              )}
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
    );
  }
};

initializeApp().catch((error) => console.error('Ошибка инициализации приложения:', error));
